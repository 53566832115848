import React from 'react';
import { Redirect } from "react-router-dom";
export default function Home() {
    const BUID = sessionStorage.getItem('resto-loyalty-buid');

    return (
        <React.Fragment>
            {BUID ? <Redirect to={`/${btoa(BUID ? BUID : '')}/crew`} /> : null}
        </React.Fragment>
    );
}