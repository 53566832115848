import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router'
import { useForm } from "react-hook-form";
import { GET, POST, API_URL } from '../../_config';

export default function Login() {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [loginError, setLoginError] = useState<string | null>(null);
    const [user, setUser] = useState<boolean | null>(false);
    const [STORE_DATA, set_STORE_DATA] = useState<any>({});
    const BUID = sessionStorage.getItem('resto-loyalty-buid');

    const onSubmit = (data: any) => {
        if (BUID) {
            data.businessunitid = parseInt(BUID);
        }

        POST(API_URL.USER_LOGIN, data).then((resp: any) => {
            sessionStorage.setItem('loyalty-user-id-' + BUID, "1431");
            if (parseInt(resp.data) !== 0) {
                sessionStorage.setItem('loyalty-user-id-' + BUID, resp.data);
                setUser(true);
            } else {
                setLoginError('Username/Password is incorrect.');
            }

        }).catch((err: any) => {

        })
    }

    const getBGStyle = () => {
        if (STORE_DATA?.CrewPortalBackground) {
            return { backgroundImage: `url(${STORE_DATA?.CrewPortalBackground})` }
        } else {
            return {}
        }
    }

    useEffect(() => {
        let storeData: any = sessionStorage.getItem('resto-store-' + BUID);
        if (storeData) {
            //storeData.StoreBackground = null;
            set_STORE_DATA(JSON.parse(storeData))
        }
    }, [])
    return (
        <React.Fragment>
            {user ? <Redirect to={`/${btoa(BUID ? BUID : '')}/crew`} /> : null}
            <section className="login full-container loyalty-bg-crew" style={getBGStyle()}>




                <form id="loginForm" onSubmit={handleSubmit(onSubmit)}>
                    <div className="login-logo">
                        <img src={STORE_DATA?.Logo} alt="" />
                    </div>
                    <h1>LOGIN</h1>
                    {loginError ? <p className="login-error">{loginError}</p> : null}

                    <div className="field">
                        <label>Email</label>
                        <input type="email" placeholder="Email" {...register("username", { required: true, pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ })} />
                        {errors?.email ? <div className="invalid-feedback">
                            {errors.email.type === "required" ? 'required' : null}
                            {errors.email.type === "pattern" ? 'invalid' : null}
                        </div> : null}
                    </div>
                    <div className="field">
                        <label>Password</label>
                        <input type="password" placeholder="password" {...register("password", { required: true, maxLength: 80 })} />
                        {errors?.password ? <div className="invalid-feedback">required</div> : null}
                    </div>
                    <button type="submit">Login</button>
                </form>



                <div className="powered-by"><span>Powered by LoyaltyNXT</span></div>
            </section>
        </React.Fragment>
    );
}