
import { Redirect } from 'react-router'
import AuthLayout from '../layout/AuthLayout'

const requireAuth = (Component: React.ComponentType) => {
  function AuthHoc(props: any) {
    const BUID = sessionStorage.getItem('resto-loyalty-buid');

    const isAuth = sessionStorage.getItem('loyalty-user-id-' + BUID)

    return (
      isAuth ? <AuthLayout>
        <Component {...props} />
      </AuthLayout> : <Redirect to={`/${btoa(BUID ? BUID : '')}/crew/login`} />
    )
  }

  return AuthHoc
}
export default requireAuth;



