import React, { useEffect, useState } from "react";
import { GET, POST, API_URL } from '../_config'


import { GiCreditsCurrency, GiMoneyStack } from 'react-icons/gi';
import { FiEdit } from 'react-icons/fi';
import { BsTagFill } from 'react-icons/bs';
import { clearInterval } from "timers";

const StampModal = (props: Record<string, any>) => {

    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const USERID = sessionStorage.getItem('loyalty-user-id-' + BUID);







    return (
        <React.Fragment>
            {props.show ?
                props?.data?.length ?
                    <div className={"add-modal"}>
                        <div className="modal page-center stamp-card">
                            <h1 className="stamp-title">Reward Milestones</h1>
                            <div className="mile-data">
                                {props.data.map((stamp: any, i: number) => (
                                    <div className="stamp-details" key={i}>
                                        <div className="badge">{stamp.Stamps}</div>
                                        <label>{stamp.LoyaltyRewards}</label>
                                    </div>
                                ))}
                            </div>

                            <div className="btns"><button className="confirm" onClick={() => { props.onHide() }}>Got it</button></div>
                        </div>
                    </div>
                    : null : null}
        </React.Fragment>
    )
};

export default StampModal