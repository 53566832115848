import React from 'react'
import { Redirect } from 'react-router'
import NoAuthLayout from '../layout/NoAuthLayout'

const requireNoAuth = (Component: React.ComponentType) => {
  function NoAuthHoc(props: any) {
    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const isAuth = sessionStorage.getItem('loyalty-user-id-' + BUID)

    return (
      isAuth ? <Redirect to={`/${btoa(BUID ? BUID : '')}/crew/`} /> : <NoAuthLayout>
        <Component {...props} />
      </NoAuthLayout>
    )
  }

  return NoAuthHoc
}
export default requireNoAuth
