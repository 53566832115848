import React, { useEffect, useState } from 'react';
import Header from '../../component/header';
import Footer from '../../component/footer';
import UserFinder from '../../component/userFinder';
import Modal from '../../component/modal';
import { GET, POST, API_URL } from '../../_config'
export default function Credits() {
    const [newUser, setNewUser] = useState<boolean>(false);
    const [number, setNumber] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [bundles, setBundles] = useState<any>(null);
    const [bundle, setBundle] = useState<any>(null);
    const [status, setStatus] = useState<any>('');
    const [modal, setModal] = useState<boolean>(false);
    const [STORE_DATA, set_STORE_DATA] = useState<any>({});
    const modalClose = () => {
        setBundle(null);
        setModal(false);
    }

    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const USERID = sessionStorage.getItem('loyalty-user-id-' + BUID);

    const onUser = (user: any, number: any) => {
        console.log("User finder =>", user)
        if (user) {
            setNewUser(user.NewUser);
            setNumber(number);
            setUser(user);
            if (!user.NewUser) {
                //getBundles();
            }
        } else {
            setNewUser(false);
            setNumber(null);
            setUser(null);
        }
    }

    const addUser = () => {
        setNewUser(false);
        POST(API_URL.ADD_USER, {
            loyaltyuserid: 0,//_this.user.id,
            fkbusinessunitid: BUID,
            mobile: number,
            name: null,
            email: null,
            dob: null
        }).then((resp: any) => {
            setUser(resp.data);
            //getBundles();
        }).catch((err: any) => {

        })
    }

    const buttonLabel = () => {

        let input: any = document.querySelector('[name="creditInput"]');

        let value = input?.value;
        switch (status) {
            case 'working': return 'Adding...';
            case 'success': return STORE_DATA?.CurrencyShortCode + ' ' + parseFloat(value).toFixed(2) + ' Credit Added.';
            case 'error': return 'Something Wrong! Please try after sometimes.';
            default: return 'Add Credit';
        }
    }


    const addCredit = () => {
        let input: any = document.querySelector('[name="creditInput"]');
        //let credit: any = document.querySelector('.credit-input');
        if (input) {
            let value = input.value;
            console.log('----', value)

            if (value == "" || value == 0) {
                //credit?.classList.add('error');
            } else {
                setStatus('working');
                POST(API_URL.ADD_CREDIT, {
                    "fkbusinessunitid": BUID,
                    "fkuserid": USERID,
                    "fkLoyaltyUserID": user.ID,
                    "transactions": value
                }).then((resp: any) => {
                    setStatus('success');
                    input.value = 0
                    setTimeout(() => { setStatus('') }, 2000)
                }).catch((err: any) => {
                    setStatus('error');
                    setTimeout(() => { setStatus('') }, 2000)
                })
            }
        }


    }

    const getBGStyle = () => {
        if (STORE_DATA?.CrewPortalBackground) {
            return { backgroundImage: `url(${STORE_DATA?.CrewPortalBackground})` }
        } else {
            return {}
        }
    }

    useEffect(() => {
        let storeData: any = sessionStorage.getItem('resto-store-' + BUID);
        if (storeData) {
            set_STORE_DATA(JSON.parse(storeData))
        }
    }, [])

    return (
        <React.Fragment>
            <div className="fixed-background-bg loyalty-bg-crew" style={getBGStyle()}></div>
            <Header />
            <section className="view-area">
                <h2>Credit</h2>
                <UserFinder onUser={onUser} />
                {newUser ?
                    <div className="newuser card" onClick={addUser}>
                        <div className="number">+919830505538</div>
                        <div className="label">New User</div>
                    </div>
                    :
                    user ?
                        <div className="section">
                            <h2 className="title">How much would you like to add?</h2>
                            <div className="credit-input">
                                <div className="icon">
                                    <div className="currency" style={{ color: '#646e75' }}>{STORE_DATA?.CurrencyShortCode}</div>
                                </div>
                                <input type="number" name="creditInput" step="0.01" min="0.01" placeholder="0.00" />
                            </div>

                            <div className={"card block-button " + status} onClick={addCredit}>{buttonLabel()}</div>
                        </div> : null
                }




            </section>
            <Footer />
        </React.Fragment>
    );
}