import { useEffect, useState } from "react";
import intlTelInput from 'intl-tel-input';
import { GET, POST, API_URL } from '../_config'
import { MdPlayArrow } from 'react-icons/md';

import React from "react";

interface incomingProps {
    onUser: (user: any, number: any) => void;
    userObject?: any;
    onReset?: any
}

export default function UserFinder({ onUser, userObject, onReset }: incomingProps) {
    const [telObject, setTelObject] = useState<any>(null);
    const [user, setUser] = useState<any>(null);
    const [number, setNumber] = useState<any>(null);
    const [isValidNumber, setIsValidNumber] = useState<any>(null);
    const [iti, setIti] = useState<any>(null);
    const [cc, set_cc] = useState<any>(null);
    const BUID = sessionStorage.getItem('resto-loyalty-buid');
    const [STORE_DATA, set_STORE_DATA] = useState<any>(null);

    const getUser = (number: any) => {
        sessionStorage.setItem('current-user-number' + BUID, number)
        GET(API_URL.CHECK_USER, { fkbusinessunitid: BUID, mobile: number },).then((resp: any) => {
            setUser(resp.data);
            onUser(resp.data, number)
        }).catch((err: any) => {
            onUser(null, null)
        })

    }

    const reset = () => {
        onUser(null, null);
        setUser(null);
        sessionStorage.removeItem('current-user-number' + BUID);
        iti.setNumber('');
    }


    const Country = () => {


        fetch('http://ip-api.com/json')
            .then(response => response.json())
            .then(data => {
                set_cc(data.countryCode)
            });
    }

    useEffect(() => {
        if (isValidNumber) {

            getUser(iti.getNumber())
        } else {
            onUser(null, null);
            //alert('remove')
            //sessionStorage.removeItem('current-user-number' + BUID)
        }
    }, [isValidNumber]);

    useEffect(() => {
        if (iti && iti.isValidNumber()) { getUser(iti.getNumber()) } else {
            onUser(null, null);
            if (telObject?.value == '') {
                iti.setNumber(STORE_DATA?.CountryCode ? STORE_DATA?.CountryCode : '+1');
                setTimeout(() => {
                    iti.setNumber('');
                }, 100)
            }

        }
    }, [iti]);

    useEffect(() => {
        let storeData: any = sessionStorage.getItem('resto-store-' + BUID);
        if (storeData) {
            set_STORE_DATA(JSON.parse(storeData))
        }
    }, [])


    useEffect(() => {
        if (telObject && STORE_DATA) {
            telObject.value = sessionStorage.getItem('current-user-number' + BUID)
            setIti(intlTelInput(telObject, {
                nationalMode: true,
                formatOnDisplay: false,
                initialCountry: '',
                autoHideDialCode: true,
                autoPlaceholder: 'off',
                utilsScript: 'https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.13/js/utils.js'
            }));
        }

    }, [telObject, STORE_DATA])
    useEffect(() => {
        //Country();
        setTelObject(document.getElementById('telNumber'));
    }, []);

    useEffect(() => {
        setUser(userObject)
    }, [userObject])
    useEffect(() => {
        if (onReset) {
            reset();
        }
    }, [onReset])

    return (
        <div className="user-finder">
            {user ? !user.NewUser ? <div className="totalStamp">{user?.Details?.LoyaltyCardDetails.objCoinStamp?.[0]?.ActiveStamp}</div> : null : null}
            <span></span>
            <input id="telNumber"
                onKeyUp={() => { setIsValidNumber(iti.isValidNumber()) }}
                onKeyDown={() => { sessionStorage.removeItem('current-user-number' + BUID); }}
                className="mobile"
                type="tel"
                inputMode="tel"
                autoComplete="off"
                pattern="[\d ()]*"
                placeholder="Type your mobile number" />
            {user ?
                !user.NewUser ?
                    <div className="life-time-stamp">
                        {user.Details?.Name ?
                            <React.Fragment>
                                {user.Details?.Name} <MdPlayArrow /> Lifetime stamp:
                            </React.Fragment>
                            : 'Lifetime stamp:'
                        } {user?.Details?.LoyaltyCardDetails?.objCoinStamp?.[0]?.LifitimeStamp}
                    </div> : null
                : null}
        </div>
    )
}