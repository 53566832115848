import React from 'react';
import logo from './logo.svg';
import './App.scss';

import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';



import Home from './pages/home';
import Store from './pages/store';
import Crew from './pages/crew';
import History from './pages/crew/history';
import Bundles from './pages/crew/bundles';
import Credits from './pages/crew/credits';
import Login from './pages/crew/login';
import requireNoAuth from './_common/hoc/reqNoAuth'
import requireAuth from './_common/hoc/reqAuth'


function App() {
  return (
    <Router>
      <Switch>
        {/* <Route exact path="/" component={() => (
            <Redirect to={URLS.USER.LOGIN}/>
          )} /> */}
        <Route exact path="/" component={requireNoAuth(Home)} />
        <Route exact path="/:slug" component={requireNoAuth(Home)} />
        <Route exact path="/:slug/store" component={Store} />
        <Route exact path="/:slug/crew" component={requireAuth(Crew)} />
        <Route exact path="/:slug/crew/history" component={requireAuth(History)} />
        <Route exact path="/:slug/crew/bundles" component={requireAuth(Bundles)} />
        <Route exact path="/:slug/crew/credits" component={requireAuth(Credits)} />
        <Route exact path="/:slug/crew/login" component={requireNoAuth(Login)} />


      </Switch>
    </Router>
  );
}

export default App;
