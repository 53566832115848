import { useEffect } from "react";

interface incomingProps {
    counter: number;
    label: string;
    steps: number;
    workingSteps: string;
    value: number;
}

export default function CircleSteps({ counter, label, steps, workingSteps, value }: incomingProps) {

    const setValue = (v: any) => {
        let _v = v;
        if (_v === undefined) {
            _v = 880 - (880 / steps) * value;
        }
        let progress: any = document.querySelector('#svg .progress');
        progress.setAttribute('stroke-dashoffset', _v)
    }

    const removeElementsByClass = (className: any) => {
        const elements: any = document.getElementsByClassName(className);
        while (elements.length > 0) {
            elements[0].parentNode.removeChild(elements[0]);
        }
    }

    const clearSteps = () => {
        setValue(0);
        removeElementsByClass('track-pointer');
        removeElementsByClass('track-pointer-info');
        removeElementsByClass('track-pointer-info-text');
    }

    const createSteps = () => {
        clearSteps();
        let svg: any = document.getElementById('svg');
        let r = 140;
        let cx = 225;
        let cy = 225;

        let visibleSteps = workingSteps.split(',');
        for (let i = 1; i <= steps; i++) {
            if (visibleSteps.indexOf(i.toString()) >= 0) {
                let deg = (360 / steps * i) - 90;
                if (i === steps) { deg -= 15 }
                let rad = deg * (Math.PI / 180);
                let x = cx + r * Math.cos(rad);
                let y = cy + r * Math.sin(rad);

                let x1 = cx + (r + 55) * Math.cos(rad);
                let y1 = cy + (r + 55) * Math.sin(rad);
                svg.innerHTML += `<circle class="track-pointer" cx="${x}" cy="${y}"></circle>
                <circle class="track-pointer-info" cx="${x1}" cy="${y1}" style="filter: url(&quot;#tracker-pointer-shadow&quot;);"></circle>
                <text class="track-pointer-info-text" x="${x1}" y="${y1}" dy="2">${i}</text>`
            }

        }
        setValue(undefined);
    }

    useEffect(() => {
        createSteps();
    }, [counter, label, steps, workingSteps, value]);


    return (
        <svg viewBox="0 0 450 450" id="svg">
            <defs>
                <filter id="tracker-pointer-shadow" x="-40%" y="-40%" width="180%" height="180%">
                    <feDropShadow dx="0" dy="0" stdDeviation="4" floodOpacity="0.2"></feDropShadow>
                </filter>
            </defs>
            <circle className="base-circle" r="140" strokeWidth="40" cx="225" cy="225"></circle>
            <path className="progress" r="140" strokeWidth="40" d="M 224.99999999999997 85 A 140 140 0 1 1 224.99120354057573 85.00000027634894" strokeDasharray="879.6371465457121" strokeDashoffset="601.0853834729032"></path>

            <text className="counter" x="225" y="210">{counter}</text>
            <text className="label" x="225" y="270">{label}</text>


        </svg>
    )
}